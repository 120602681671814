import { Block, Color } from '@snowflake/core-ui';
import cookies from 'browser-cookies';
import React from 'react';
import * as configs from './configurations';

const BEACH_COOKIE = 'snowflake_growth_channel';
const beachCookieValue = cookies.get(BEACH_COOKIE) || null;
const clearBeachCookie = () => {
  cookies.erase(BEACH_COOKIE);
  window.location.replace(window.location.origin + window.location.pathname);
};

export const SignupBeachHeader = () => {
  return beachCookieValue ? (
    <Block
      onClick={clearBeachCookie}
      backgroundColor={Color.Blue50}
      color={Color.White}
      cursor="pointer"
      display="flex"
      height="20px"
      justifyContent="center"
      opacity={0.5}
      position="absolute"
      width="100%"
      zIndex={10}
    >
      {beachCookieValue} ({configs.getClientVersion()}) (Click to Clear)
    </Block>
  ) : (
    <></>
  );
};

import { Color, Text } from '@snowflake/core-ui';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

export const PolarisTitle = () => {
  return (
    <>
      <Text
        className="trial-title"
        allCaps={true}
        fontSize={20}
        color={Color.White}
        lineHeight="41px"
        marginBottom={4}
      >
        <FormattedMessage
          id="Introducing"
          values={{
            linebreak: function LineBreak() {
              return <br />;
            },
          }}
        />
      </Text>
      <img src="/static/images/polaris-catalog-horz.svg" width="100%" />
      <Text
        className="trial-title"
        fontSize={20}
        color={Color.White}
        lineHeight="41px"
        marginBottom={4}
      >
        <FormattedMessage
          id="An open source catalog for Apache Iceberg"
          values={{
            linebreak: function LineBreak() {
              return <br />;
            },
          }}
        />
      </Text>
    </>
  );
};

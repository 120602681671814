import { Block, Color, Input, Link, Text, useTelemetry, ValidationState } from '@snowflake/core-ui';
import {
  AzureActivateSubscriptionPayload,
  AzurePurchaseInfo,
  AzureResolvePurchaseTokenResponse,
  isEmailValid,
  SignupApiErrorCode,
  SignupEventType,
  SignupRequestResponse,
  validateInput,
} from '@signup/shared';
import { ParsedQuery } from 'query-string';
import { default as React, FormEvent, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSignupPageContext } from '../../../pages/SignupContext';
import { AzureEventName, AzureSteps } from '../../../utils/SignupLogger';
import { useLogOnClose } from '../../UseLogOnClose';
import { CardTemplate } from '../shared/CardTemplate';
import {
  BaseCardProps,
  checkmarkIcon,
  DotsLoadingIndicator,
  exclamationIcon,
  FormButton,
  FormRow,
  sleep,
  supportedCharacters,
} from '../shared/shared';
import * as configs from '../../../utils/configurations';

export interface AzureCardProps extends BaseCardProps {
  queryParams: ParsedQuery;
}

export const AzureCard = (props: AzureCardProps) => {
  // Form options
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [billingNickname, setBillingNickname] = useState('');

  // Form Validation
  const [firstNameBlurred, setFirstNameBlurred] = useState(false);
  const [lastNameBlurred, setLastNameBlurred] = useState(false);
  const [emailBlurred, setEmailBlurred] = useState(false);
  const [billingNicknameBlurred, setBillingNicknameBlurred] = useState(false);
  const [validEmail, setValidEmail] = useState<ValidationState>(ValidationState.NONE);

  //Localization -- message formatter
  const { formatMessage } = useIntl();

  //Set up Telemetry
  const { logAction, logEvent } = useTelemetry();

  // For specific server errors on async requests
  const [errorCode, setErrorCode] = useState<SignupApiErrorCode | undefined>(undefined);
  const [accountCreationFinished, setAccountCreationFinished] = useState(false);
  const [validatingToken, setValidatingToken] = useState(false);
  const [purchaseInfo, setPurchaseInfo] = useState<AzurePurchaseInfo | undefined>(undefined);
  const [formStartTime, setFormStartTime] = useState<number | undefined>(undefined);
  const [currentStepLogInfo, setCurrentStepLogInfo] = useState({
    currentStep: AzureSteps.AZURE_INITIAL_LOAD as string,
    startTime: Date.now() / 1000,
  });

  const handleFormFocus = () => {
    if (formStartTime === undefined) {
      const currTime = Date.now() / 1000;
      setFormStartTime(currTime);
      setCurrentStepLogInfo({ currentStep: AzureSteps.AZURE_FORM, startTime: currTime });
    }
  };

  useLogOnClose({
    message: 'exit_azure_' + currentStepLogInfo.currentStep,
    startTime: currentStepLogInfo.startTime,
  });

  const { formSubmitted } = useSignupPageContext();

  // Form Handling and Validation
  const formValidationErrors = React.useMemo(() => {
    const invalidFields = [];
    !validateInput(firstName) && invalidFields.push('firstName');
    !validateInput(lastName) && invalidFields.push('lastName');
    !(email.trim().length && validEmail === ValidationState.VALID) && invalidFields.push('email');
    !billingNickname.trim().length && invalidFields.push('billingNickname');

    return invalidFields;
  }, [firstName, lastName, email, billingNickname, validEmail]);

  async function handleSubmit(e: FormEvent) {
    if (e.preventDefault !== undefined) e.preventDefault();

    if (formValidationErrors.length) {
      setFirstNameBlurred(true);
      setLastNameBlurred(true);
      setEmailBlurred(true);
      setBillingNicknameBlurred(true);

      logAction(AzureEventName.AZURE_FORM_VALIDATION_ERROR_ON_SUBMIT, 'ui_click', 'AzureCard', {
        formValidationErrors,
      });
    } else {
      const recaptchaToken = await props.recaptchaRef.current.executeAsync();
      // For google tag manager
      configs.getDataLayer().push({ event: 'azure marketplace subscription signup' });

      formSubmitted.setValue(true);

      const payload: AzureActivateSubscriptionPayload = {
        purchaseInfo: purchaseInfo as AzurePurchaseInfo,
        formInfo: {
          firstName: firstName.trim(),
          lastName: lastName.trim(),
          email: email.trim().toLowerCase(),
          billingNickname: billingNickname.trim(),
        },
        recaptchaToken: recaptchaToken,
        formId: props.formId,
      };

      logAction(AzureEventName.AZURE_FORM_COMPLETE, 'ui_click', 'AzureCard', {
        ...payload,
        ...(payload.recaptchaToken && { recaptchaToken: 'REDACTED' }),
      });

      const thankYouPageStartTime = Date.now() / 1000;
      setCurrentStepLogInfo({
        currentStep: AzureSteps.AZURE_THANK_YOU_PAGE,
        startTime: thankYouPageStartTime,
      });

      try {
        const rawRes = await fetch('/azure/activate_subscription', {
          method: 'post',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });
        const res: SignupRequestResponse = await rawRes.json();
        if (res.success !== true) {
          await sleep(1000);
          setErrorCode(res.errorCode);
          logEvent({
            event: AzureEventName.AZURE_ACCOUNT_CREATION_ERROR,
            type: SignupEventType.UI_RESPONSE_ERROR,
            data: {},
            interaction: false,
          });
        }
        setAccountCreationFinished(true);
        logEvent({
          event: AzureEventName.AZURE_SUCCESS,
          type: SignupEventType.UI_RESPONSE_SUCCESS,
          data: {},
          interaction: false,
        });
      } catch (err) {
        await sleep(1000);
        setErrorCode(SignupApiErrorCode.GENERIC_ERROR);
        logEvent({
          event: AzureEventName.AZURE_ACCOUNT_CREATION_ERROR,
          type: SignupEventType.UI_RESPONSE_ERROR,
          data: {},
          interaction: false,
        });
      }
    }
  }

  const resolvePurchaseToken = async () => {
    setValidatingToken(true);
    const token = props.queryParams?.token;

    if (!token) {
      setErrorCode(SignupApiErrorCode.AZURE_INVALID_TOKEN);
      logEvent({
        event: AzureEventName.AZURE_TOKEN_ERROR,
        type: SignupEventType.UI_RESPONSE_ERROR,
        data: {
          message: 'Azure token missing',
        },
        interaction: false,
      });
      setValidatingToken(false);
    } else {
      try {
        const rawRes = await fetch('/azure/resolvepurchasetoken', {
          method: 'post',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token }),
        });
        const res: AzureResolvePurchaseTokenResponse = await rawRes.json();
        if (res.success === true) {
          setPurchaseInfo(res.purchaseInfo);
        } else {
          setErrorCode(res.errorCode);
        }
      } catch (err) {
        // this is when the request errors in an unexpected way
        await sleep(1000);
        setErrorCode(SignupApiErrorCode.GENERIC_ERROR);
        logEvent({
          event: AzureEventName.AZURE_ACCOUNT_CREATION_ERROR,
          type: SignupEventType.UI_RESPONSE_ERROR,
          interaction: false,
        });
      } finally {
        setValidatingToken(false);
      }
    }
  };

  // Form
  const renderForm = () => {
    return (
      <>
        <Block onFocus={handleFormFocus}>
          <Input
            value={firstName}
            placeholder={formatMessage({ id: 'First Name' })}
            onChange={e => setFirstName(e.currentTarget.value)}
            validation={val => validateInput(val)}
            required={firstNameBlurred}
            onBlur={() => setFirstNameBlurred(true)}
          />
          <FormRow>
            <Input
              value={lastName}
              placeholder={formatMessage({ id: 'Last Name' })}
              onChange={e => setLastName(e.currentTarget.value)}
              validation={val => validateInput(val)}
              required={lastNameBlurred}
              onBlur={() => setLastNameBlurred(true)}
            />
          </FormRow>
          <FormRow>
            <Input
              value={email}
              placeholder={formatMessage({ id: 'Company Email' })}
              onChange={e => setEmail(e.currentTarget.value)}
              validation={validateEmail}
              required={emailBlurred}
              onBlur={() => {
                logAction(AzureEventName.AZURE_VALIDATE_EMAIL, 'ui_keyboard', 'AzureCard', {
                  emailValidationState: validEmail,
                  email,
                });
                setEmailBlurred(true);
              }}
              onValidationChange={setValidEmail}
            />
          </FormRow>
          <FormRow>
            <Text size="small" color={Color.Gray70}>
              <FormattedMessage id="Please create a nickname for this billing subscription, so you can associate other Snowflake accounts with this subscription in the future." />
            </Text>
          </FormRow>
          <FormRow>
            <Input
              value={billingNickname}
              placeholder={formatMessage({ id: 'Example: Analytics Team' })}
              onChange={e => setBillingNickname(e.currentTarget.value)}
              validation={val => val && !!val.trim()}
              required={billingNicknameBlurred}
              onBlur={() => setBillingNicknameBlurred(true)}
            />
          </FormRow>
          <FormButton onClick={handleSubmit}>
            <FormattedMessage id="Finish Setup" />
          </FormButton>
        </Block>
      </>
    );
  };

  function renderThankYouCardHeader(): [string, React.ReactChild, React.ReactChild] {
    let thankYouTitle = formatMessage({ id: 'Subscription Setup in Progress' });
    let thankYouSubtitle: React.ReactChild = (
      <FormattedMessage id="Your subscription setup may take a few minutes. While you're waiting, learn more about Snowflake." />
    );
    let icon = <DotsLoadingIndicator />;

    if (validatingToken) {
      thankYouTitle = formatMessage({ id: 'Validating Subscription Token' });
      thankYouSubtitle = <FormattedMessage id="This will only take a second." />;
      icon = <DotsLoadingIndicator />;
    } else if (errorCode === SignupApiErrorCode.AZURE_INVALID_TOKEN) {
      thankYouTitle = formatMessage({ id: 'Whoops!' });
      // Copy from Microsoft documentation: https://docs.microsoft.com/en-us/azure/marketplace/partner-center-portal/pc-saas-fulfillment-api-v2#resolve-a-purchased-subscription
      thankYouSubtitle = (
        <>
          <FormattedMessage id="We couldn't identify this purchase with the token provided - please reopen this SaaS subscription in Azure portal or in M365 Admin Center and click the 'Configure Account' or 'Manage Account' button again." />
          <Block paddingTop={12}>
            <Link external={true} href="https://portal.azure.com/">
              Azure Portal
            </Link>
          </Block>
        </>
      );
      icon = exclamationIcon;
    } else if (errorCode === SignupApiErrorCode.AZURE_SUBSCRIPTION_ALREADY_ACTIVATED) {
      thankYouTitle = formatMessage({ id: 'Whoops!' });
      thankYouSubtitle = (
        <>
          <FormattedMessage id="This subscription has already been activated. Contact your Snowflake representative if you are having trouble accessing your Snowflake account." />
        </>
      );
      icon = exclamationIcon;
    } else if (!!errorCode) {
      thankYouTitle = formatMessage({ id: 'Whoops!' });
      thankYouSubtitle = (
        <FormattedMessage id="Looks like we are having an issue setting up your subscription. Please try again later." />
      );
      icon = exclamationIcon;
    } else if (accountCreationFinished) {
      thankYouTitle = formatMessage({ id: 'You are now subscribed to Snowflake!' }).toUpperCase();
      thankYouSubtitle = (
        <FormattedMessage id="Nothing else is needed on your end. Please continue to use your Snowflake account(s) normally!" />
      );
      icon = checkmarkIcon;
    }

    return [thankYouTitle, thankYouSubtitle, icon];
  }

  const purchaseInfoDisplay = () => {
    return (
      <Block marginBottom={24}>
        <Text marginBottom={4}>
          <Text weight="semi-bold">Subscription Name: </Text>
          {`${(purchaseInfo as AzurePurchaseInfo).subscriptionName}`}
        </Text>
        <Text marginBottom={4}>
          <Text weight="semi-bold">Plan Id: </Text>
          {`${(purchaseInfo as AzurePurchaseInfo).planId}`}
        </Text>
        <Text>
          <Text weight="semi-bold">Offer Id: </Text>
          {`${(purchaseInfo as AzurePurchaseInfo).offerId}`}
        </Text>
      </Block>
    );
  };

  const renderCard = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      resolvePurchaseToken();
    }, []);
    //if form is submitted, an error occured, we have not fetched the purchase information, or we are validating the token
    if (formSubmitted.value || !!errorCode || purchaseInfo == undefined || validatingToken) {
      const [thankYouTitle, thankYouSubtitle, icon] = renderThankYouCardHeader();
      return (
        <CardTemplate title={thankYouTitle} subtitle={thankYouSubtitle} aux={icon}>
          <></>
        </CardTemplate>
      );
    } else {
      return (
        <CardTemplate
          title={formatMessage({ id: 'Thank you for subscribing to Snowflake!' })}
          subtitle={
            <FormattedMessage id="Please fill out the following information to finish setting up your subscription." />
          }
        >
          <>
            {purchaseInfoDisplay()}
            {renderForm()}
          </>
        </CardTemplate>
      );
    }
  };

  //function must live inside of the component to get access to localized strings
  const validateEmail = (value: string | null) => {
    if (value == undefined) {
      return false;
    }
    if (!supportedCharacters.test(value)) {
      //email may contain accents or special characters that are not supported by Salesforce
      return formatMessage({ id: 'Email contains unsupported characters' });
    }
    return isEmailValid(value);
  };

  return renderCard();
};

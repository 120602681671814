import { Text, useTelemetry, Row, Color, TextLink } from '@snowflake/core-ui';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { SignupEventName } from '../utils/SignupLogger';

export interface LoginLinkProps {
  isPolaris?: boolean;
}

export function LoginLink(props: LoginLinkProps) {
  const { logAction } = useTelemetry();

  const logUiClick = () => {
    logAction(SignupEventName.GOTO_LOGIN_PAGE_LINK_CLICKED, 'ui_click', 'SignupPage', {});
  };

  let snowflakeLoginUrl = 'https://app.snowflake.com/';

  //If there are any queryParams, persist them on the login screen
  const queryParams = window?.location?.search ?? '';
  if (queryParams) {
    snowflakeLoginUrl += queryParams;
  }

  return (
    <Row align="center" marginTop={12}>
      <Text color={Color.Gray70} weight="semi-bold">
        <FormattedMessage
          id="or <loginurl>sign in to an existing account</loginurl>"
          values={{
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            loginurl: function LoginUrl(...chunks: any[]) {
              return (
                <TextLink
                  href={snowflakeLoginUrl}
                  onClick={logUiClick}
                  color={props.isPolaris ? undefined : Color.Blue50}
                  style={props.isPolaris ? { color: '#007880' } : {}}
                >
                  {chunks}
                </TextLink>
              );
            },
          }}
        />
      </Text>
    </Row>
  );
}

import {
  ListingInformation,
  SignupApiResponseSuccess,
  SignupEventType,
  SignupListingInformationResponse,
} from '@signup/shared';
import { useTelemetry } from '@snowflake/core-ui';
import { useCallback, useEffect, useState } from 'react';
import { SignupEventName } from '../utils/SignupLogger';

export function useListingInformation(listingId: string | undefined) {
  const { logEvent } = useTelemetry();
  const [listingInformation, setListingInformation] = useState<ListingInformation | undefined>(
    undefined,
  );
  const [isListingInfoLoading, setIsListingInfoLoading] = useState<boolean>(true);

  const getListingInfo = useCallback(
    async (listingId: string) => {
      try {
        // We log the start so that we can track how long the end to end request took
        logEvent({
          event: SignupEventName.GET_LISTING_INFO_START,
          type: SignupEventType.UI_PERFORMANCE,
          interaction: false,
        });

        const response = await fetch(`/api/v1/listing/${listingId}`, {
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const listingResponse: SignupListingInformationResponse = await response.json();
        if (response.status >= 400) {
          // Log that there was an error fetching information if we fail to get it
          logEvent({
            event: SignupEventName.GET_LISTING_INFO_ERROR,
            type: SignupEventType.UI_RESPONSE_ERROR,
            interaction: false,
          });
        } else {
          const successResponse = listingResponse as SignupApiResponseSuccess<ListingInformation>;
          setListingInformation(successResponse.data);
          // We log the success case so that we can track how long the end to end request took
          logEvent({
            event: SignupEventName.GET_LISTING_INFO_SUCCESS,
            type: SignupEventType.UI_RESPONSE_SUCCESS,
            interaction: false,
          });
        }
      } catch (err) {
        logEvent({
          event: SignupEventName.GET_LISTING_INFO_ERROR,
          type: SignupEventType.UI_RESPONSE_ERROR,
          data: {
            error: err,
          },
          interaction: false,
        });
      }
      // We set loading to complete regardless to exit loading state in ui
      setIsListingInfoLoading(false);
    },
    [logEvent],
  );

  useEffect(() => {
    // Only make api call if the listing id is present
    if (listingId) {
      getListingInfo(listingId);
    } else {
      // If listing id is not present, listingInformation would be undefined
      // It's up to the caller to handle this case, i.e. if we have finished loading and info is not present
      setIsListingInfoLoading(false);
    }
  }, [listingId, getListingInfo]);

  return {
    listingInformation,
    isListingInfoLoading,
  };
}

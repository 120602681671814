import { Button, Color, Text } from '@snowflake/core-ui';
import * as React from 'react';
import * as configs from '../../../utils/configurations';
import { FeatureFlag } from '@signup/shared';

const background = '#00A3AD';
const border = '#007880';

const polarisButtonStyles = {
  background: background,
  borderBottomColor: background,
  borderLeftColor: background,
  borderRightColor: background,
  borderTopColor: background,
  borderRadius: '30px',
  paddingRight: '30px',
  paddingLeft: '30px',
  paddingTop: '20px',
  paddingBottom: '20px',
  color: Color.Gray00,
  ':hover': {
    backgroundColor: border,
    boxShadow: 'rgba(0, 0, 0, 0.3) 0 1px 2px',
  },
  ':active': {
    backgroundColor: border,
    boxShadow: 'none',
    transitionTimingFunction: 'step-start',
  },
};

export const PolarisButton = (props: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick: React.EventHandler<any>;
  children: React.ReactNode;
}) => {
  return (
    <Button
      use="primary"
      onClick={props.onClick}
      disabled={!configs.isFeatureEnabled(FeatureFlag.FF_ENABLE_POLARIS)}
      style={polarisButtonStyles}
    >
      <Text weight="bold" allCaps={true}>
        {props.children}
      </Text>
    </Button>
  );
};

import { Block, Button, Color, Row, Text, TextLink, useTelemetry } from '@snowflake/core-ui';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSignupPageContext } from '../../../pages/SignupContext';
import { FormRow } from '../shared/shared';
import { SignupParams } from '../../../utils/ParseQueryParams';

export interface ThankYouCardBodyProps {
  signupParams: SignupParams;
}

export const ThankYouCardBody = (props: ThankYouCardBodyProps) => {
  const { videoShowing, docsLocale } = useSignupPageContext();

  //Set up Telemetry
  const { logAction } = useTelemetry();

  const [delayVideo, setDelayVideo] = useState(false); //boolean to determine if  putting the video on screen should be delayed
  const [videoStartTime, setVideoStartTime] = useState<number>(0);

  const GettingStartedItem = (props: {
    title: React.ReactNode;
    subtitle: React.ReactNode;
    textLink: React.ReactNode;
  }) => {
    const { title, subtitle, textLink } = props;
    return (
      <FormRow align="left" verticalAlign="top">
        <Block marginRight={8}>
          <img src="/static/images/sf-arrow-icon-blue.svg" />
        </Block>
        <Block>
          <Text color={Color.Gray85} weight="semi-bold" marginBottom={4} allCaps={true}>
            {title}
          </Text>
          <Text marginBottom={4} color={Color.Gray70}>
            {subtitle}
          </Text>
          {textLink}
        </Block>
      </FormRow>
    );
  };

  //Delay putting the video on screen until the card has reached its full width
  //This allows us to have a smooth animation as the card width increases to fit the size of the video
  useEffect(() => {
    if (videoShowing.value) {
      setTimeout(() => {
        setDelayVideo(true);
      }, 250);
    } else {
      setDelayVideo(false);
    }
  }, [videoShowing.value]);

  if (videoShowing.value) {
    return (
      <>
        {delayVideo && <GettingStartedVideo />}
        <Row align="center" verticalAlign="top" marginTop={16}>
          <Button
            size="small"
            use="text"
            onClick={() => {
              logAction('close_video_click', 'ui_click', 'ThankYouCardBody', {
                timeSpentOnVideo: Date.now() / 1000 - videoStartTime,
              });
              videoShowing.setValue(false);
            }}
          >
            <FormattedMessage id="Go Back" />
          </Button>
        </Row>
      </>
    );
  } else if (props.signupParams.polaris) {
    return <></>;
  } else {
    return (
      <Block marginTop={24}>
        <GettingStartedItem
          title={<FormattedMessage id="Getting Started Video" />}
          subtitle={<FormattedMessage id="A quick tour of Snowflake's Platform." />}
          textLink={
            <TextLink
              color={Color.Blue50}
              onClick={() => {
                logAction('watch_video_link_click', 'ui_click', 'ThankYouCardBody', {});
                videoShowing.setValue(true);
                setVideoStartTime(Date.now() / 1000);
              }}
            >
              <FormattedMessage id="Watch Video" />
            </TextLink>
          }
        />
        <GettingStartedItem
          title={<FormattedMessage id="Virtual Hands-on Lab" />}
          subtitle={
            <FormattedMessage id="Instructor-led, online lab guiding you through key product features." />
          }
          textLink={
            <TextLink
              color={Color.Blue50}
              href="https://www.snowflake.com/virtual-hands-on-lab/?utm_cta=self-service-trial-thank-you-vhol"
              target="_blank"
              onClick={() => {
                logAction('virtual_lab_link_click', 'ui_click', 'ThankYouCardBody', {});
              }}
            >
              <FormattedMessage id="Reserve Your Seat" />
            </TextLink>
          }
        />
        <GettingStartedItem
          title={<FormattedMessage id="Full Snowflake Documentation" />}
          subtitle={
            <FormattedMessage id="A comprehensive document covering all aspects of Snowflake's Platform." />
          }
          textLink={
            <TextLink
              color={Color.Blue50}
              href={`https://docs.snowflake.com/${docsLocale.value}/`}
              target="_blank"
              onClick={() => {
                logAction('snowflake_docs_link_click', 'ui_click', 'ThankYouCardBody', {});
              }}
            >
              <FormattedMessage id="Go To Documentation" />
            </TextLink>
          }
        />
      </Block>
    );
  }
};

function GettingStartedVideo() {
  const { locale } = useSignupPageContext();

  const VideoIdMap = {
    'en-US': 'fwsoyaljp1',
    ja: 'emphh3gx8j',
  };

  const videoId =
    locale.value in VideoIdMap ? VideoIdMap[locale.value as 'en-US' | 'ja'] : VideoIdMap['en-US'];

  React.useEffect(() => {
    const script1 = document.createElement('script');
    script1.setAttribute('src', `https://fast.wistia.com/embed/medias/${videoId}.jsonp`);

    const script2 = document.createElement('script');
    script2.setAttribute('src', 'https://fast.wistia.com/assets/external/E-v1.js');

    document.body.appendChild(script1);
    document.body.appendChild(script2);
  }, [videoId]);
  return (
    <div>
      <div
        className="wistia_responsive_padding"
        style={{ padding: '56.25% 0 0 0', position: 'relative' }}
      >
        <div
          className="wistia_responsive_wrapper"
          style={{ height: '100%', left: '0', position: 'absolute', top: '0', width: '100%' }}
        >
          <div
            className={`wistia_embed wistia_async_${videoId} videoFoam=true`}
            style={{ height: '100%', position: 'relative', width: '100%' }}
          >
            <div
              className="wistia_swatch"
              style={{
                height: '100%',
                left: '0',
                opacity: '0',
                overflow: 'hidden',
                position: 'absolute',
                top: '0',
                transition: 'opacity 200ms',
                width: '100%',
              }}
            >
              <img
                src={`https://fast.wistia.com/embed/medias/${videoId}/swatch`}
                style={{
                  filter: 'blur(5px)',
                  height: '100%',
                  objectFit: 'contain',
                  width: '100%',
                }}
                alt=""
                aria-hidden="true"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { useTelemetry } from '@snowflake/core-ui';
import React, { useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { SignupEventName } from '../../../utils/SignupLogger';
import { CardTemplate } from '../shared/CardTemplate';
import { QuestionResult } from './model';
import {
  usePreferredLanguageQuestion,
  useSignupReasonQuestion,
  useSnowflakeUsageQuestion,
} from './questions';
import { SurveyQuestion } from './SurveyQuestion';
import { shuffle } from 'lodash';

type SurveyCardProps = {
  markAsSubmitted: () => void;
};

export function SurveyCard(props: SurveyCardProps) {
  const { formatMessage } = useIntl();
  const { logAction } = useTelemetry();

  const surveyQuestionsRaw = [
    useSignupReasonQuestion(),
    useSnowflakeUsageQuestion(),
    usePreferredLanguageQuestion(),
  ];
  const { current: surveyQuestionsOrder } = useRef([0, ...shuffle([1, 2])]);
  const surveyQuestions = surveyQuestionsOrder.map(index => surveyQuestionsRaw[index]);

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const currentQuestion = surveyQuestions[currentQuestionIndex];
  const currentStep = currentQuestionIndex + 1;
  const totalSteps = surveyQuestions.length;
  const isLastQuestion = currentStep === totalSteps;

  const nextQuestionOrMarkSubmitted = () => {
    if (isLastQuestion) {
      props.markAsSubmitted();
    } else {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const onQuestionSubmit = (result: QuestionResult) => {
    const telemetryEntry = {
      question: result.questionValue,
      version: result.version,
      selection: {
        ...result.options,
        otherUserInput: result.otherOptionInput,
      },
      questionOrder: surveyQuestions.map(q => q.questionText.value),
      optionOrder: currentQuestion.options.map(o => o.value),
    };
    logAction(
      SignupEventName.POST_SIGNUP_ACQUISITION_SOURCE_SURVEY,
      'ui_click',
      'SurveyCard',
      telemetryEntry,
    );
    nextQuestionOrMarkSubmitted();
  };

  const surveyTitle = formatMessage({ id: 'Thanks for signing up with Snowflake!' });
  const surveySubtitle: React.ReactChild = (
    <FormattedMessage id="Help us better serve you by answering these questions." />
  );

  return (
    <CardTemplate title={surveyTitle} subtitle={surveySubtitle}>
      <SurveyQuestion
        currentStep={currentStep}
        totalSteps={totalSteps}
        onSubmit={onQuestionSubmit}
        onSkip={nextQuestionOrMarkSubmitted}
        question={currentQuestion}
      />
    </CardTemplate>
  );
}

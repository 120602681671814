import { Block, Color, Text } from '@snowflake/core-ui';
import { Cloud, Edition } from '@signup/shared';
import * as _ from 'lodash';
import * as uuid from 'uuid';
import * as React from 'react';
import { SignupCard } from '../components/Cards/forms/SignupCard/SignupCard';
import { Footer } from '../components/Footer';
import { SignupBeachHeader } from '../utils/SignupBeachHeader';
import { SignupPageProps } from './SignupPageRoot';
import { MarketingParams, parseQueryParams, SignupParams } from '../utils/ParseQueryParams';
import { PolarisTitle } from '../components/MarketingText/PolarisTitle';
import { FormattedMessage } from 'react-intl';
const recaptchaRef = React.createRef();

const PolarisInfoListItem = (props: { children: React.ReactNode }) => {
  return (
    <Block display="flex" justifyContent="space-between" verticalAlign="top" marginBottom={12}>
      <Block marginTop="6px" marginRight={16}>
        <img src="/static/images/dark-rectangle-bullet.svg" />
      </Block>
      <Text color={Color.White} size="large" lineHeight="30px" marginBottom={12} width="100%">
        {props.children}
      </Text>
    </Block>
  );
};

export const PolarisSignupPage = (props: SignupPageProps) => {
  const [signupParams, marketingParams]: [SignupParams, MarketingParams] = React.useMemo(() => {
    return parseQueryParams(props.location?.search);
  }, [props.location]);

  if (props.developers) {
    signupParams.developers = true;
  }

  signupParams.polaris = true;
  signupParams.plan = Edition.STANDARD;

  const renderPolarisTitle = () => {
    return <PolarisTitle />;
  };

  /* eslint-disable react/jsx-key */
  const marketingText = [
    <FormattedMessage id="Manage Iceberg tables for all users and engines in one place" />,
    <FormattedMessage id="Streamline data management with centralized access" />,
    <FormattedMessage id="Cross-engine read and write interoperability - read and write from any REST-compatible engine" />,
    <FormattedMessage id="Vendor-agnostic flexibility - run anywhere without lock-in" />,
    <FormattedMessage id="Get started with Polaris Catalog on Snowflake’s AI Data Cloud in minutes, or self-host in your own infrastructure" />,
  ];

  return (
    <Block className="trial-page-container">
      <SignupBeachHeader />
      <Block className={`trial-responsive-flex-container polaris`}>
        <Block
          className="trial-left-container"
          display="flex"
          flexDirection="column"
          justifyContent="top"
        >
          {renderPolarisTitle()}
          <Block marginTop="48px">
            {marketingText.map(text => {
              return <PolarisInfoListItem key={uuid.v4()}>{text}</PolarisInfoListItem>;
            })}
          </Block>
        </Block>
        <Block
          className="trial-right-container"
          display="flex"
          flexDirection="column"
          justifyContent="top"
          maxWidth={'420px'}
        >
          <SignupCard
            signupParams={{ ...signupParams, cloud: Cloud.AWS }}
            marketingParams={marketingParams}
            formId={props.formId}
            recaptchaRef={recaptchaRef}
            awsType={props.awsType}
          />
        </Block>
      </Block>
      <Footer recaptchaRef={recaptchaRef} pageNotFound={props.pageNotFound} />
    </Block>
  );
};

import { Color, Icon, Row, RowProps } from '@snowflake/core-ui';
import { default as React, RefObject } from 'react';
import { MarketingParams, SignupParams } from '../../../utils/ParseQueryParams';
import { MarketingButton } from './MarketingButton';
import { PolarisButton } from './PolarisButton';
import * as configs from '../../../utils/configurations';

export interface BaseCardProps {
  signupParams: SignupParams;
  marketingParams: MarketingParams;
  formId: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  recaptchaRef: RefObject<any>;
}

export const FormRow = (props: RowProps) => <Row marginTop={16} {...props} />;

export const FormButton = (props: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick: React.EventHandler<any>;
  children: React.ReactNode;
  isPolaris?: boolean;
}) => {
  return (
    <Row align="center" verticalAlign="top" marginTop={24}>
      {props.isPolaris ? (
        <PolarisButton onClick={props.onClick}>{props.children}</PolarisButton>
      ) : (
        <MarketingButton onClick={props.onClick}>{props.children}</MarketingButton>
      )}
    </Row>
  );
};

export const exclamationIcon = <Icon icon="warning" size={32} color={Color.Red50} />;
export const checkmarkIcon = <Icon icon="checkmark" size={32} color={Color.Blue50} />;
export const supportedCharacters = /^[a-z0-9!#$%&'*+/=?^_`{|}~.@-]*$/i;

export function sleep(millis: number) {
  return new Promise(resolve => setTimeout(resolve, millis));
}

export function DotsLoadingIndicator() {
  return (
    <div className="dot-animation">
      <div className="root">
        <li className="dot" />
        <li className="dot" />
        <li className="dot" />
      </div>
    </div>
  );
}

export function getCurrentRootUrl(): string {
  let url = `${window.location.protocol}//${window.location.hostname}`;
  if (configs.getBuildEnv() !== 'production') {
    url += `:${window.location.port}`;
  }
  return url;
}

import { Block, Button, CheckList, Input, LinkButton, Row, Text } from '@snowflake/core-ui';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { isOtherOption, LabeledValue, OptionLabeledValue, Question, QuestionResult } from './model';
import { ProgressBar } from './ProgressBar';

export type SurveyQuestionProps = {
  readonly question: Question;
  readonly currentStep: number;
  readonly totalSteps: number;
  readonly onSubmit: (selections: QuestionResult) => void;
  readonly onSkip: () => void;
};

const initialState: { [key: string]: boolean } = {};

export function SurveyQuestion(props: SurveyQuestionProps) {
  const { formatMessage } = useIntl();

  const { options: surveyOptions, questionText } = props.question;
  const { currentStep, totalSteps } = props;
  const isLastQuestion = currentStep === totalSteps;

  const [selectedOptions, setSelectedOptions] = useState(initialState);
  const [otherText, setOtherText] = useState<string>('');

  const isOtherTextEnabled = (option: OptionLabeledValue): boolean =>
    option.allowUserInput === true && isOtherOption(option);

  const onSubmit = () => {
    const { questionText: question, version } = props.question;

    const answers = surveyOptions.reduce((acc, curr) => {
      return acc[curr.value] !== undefined ? acc : { ...acc, [curr.value]: false };
    }, selectedOptions);

    const currentState: QuestionResult = {
      version: version,
      questionValue: question.value,
      options: answers,
      otherOptionInput: otherText,
    };
    setSelectedOptions(initialState);
    setOtherText('');
    props.onSubmit(currentState);
  };

  const onSkip = () => {
    setSelectedOptions(initialState);
    setOtherText('');
    props.onSkip();
  };

  const onToggleSelection = (option: LabeledValue) => {
    const currentState = selectedOptions[option.value] || false;
    const updatedOptions = {
      ...selectedOptions,
      [option.value]: !currentState,
    };
    setSelectedOptions(updatedOptions);

    // Let's clear text in other box if unchecked
    if (isOtherTextEnabled(option) && currentState === true) {
      setOtherText('');
    }
  };

  //submit button is disabled if user has not selected any options in the survey
  const isSubmitButtonDisabled = Object.values(selectedOptions).every(val => val === false);

  const createLabelFor = (option: OptionLabeledValue): any => {
    if (isOtherTextEnabled(option)) {
      return (
        <Row verticalAlign="top">
          <Text>{option.label}</Text>
          <Input
            value={otherText}
            onChange={event => setOtherText(event.target.value)}
            placeholder={formatMessage({ id: 'Tell us more' })}
            size="small"
            marginLeft={8}
            marginTop="-3px"
            disabled={selectedOptions[option.value] !== true}
            maxLength={50}
          />
        </Row>
      );
    }
    return option.label;
  };

  const renderProgressBar = () => {
    if (totalSteps === 1) {
      return null;
    }
    return (
      <Block paddingBottom={12} role="progressbar">
        <ProgressBar currentStep={currentStep} totalSteps={totalSteps} />
      </Block>
    );
  };

  return (
    <>
      {renderProgressBar()}
      <Text weight="semi-bold" paddingBottom={16}>
        {questionText.label}
      </Text>
      <CheckList
        options={surveyOptions.map((option, index) => {
          return {
            id: `option-${index}`,
            checked: selectedOptions[option.value] || false,
            value: option.value,
            label: createLabelFor(option),
            onChange: () => onToggleSelection(option),
          };
        })}
      />
      <Row align="right" paddingTop={16}>
        <LinkButton paddingRight={4} use="text" href="#" onClick={onSkip}>
          <FormattedMessage id="Skip" />
        </LinkButton>
        <Button use="primary" onClick={onSubmit} disabled={isSubmitButtonDisabled}>
          {isLastQuestion ? <FormattedMessage id="Submit" /> : <FormattedMessage id="Continue" />}
        </Button>
      </Row>
    </>
  );
}

import { Block, Color, Shimmer, Text } from '@snowflake/core-ui';
import { AwsMarketplaceType, FeatureFlag, ListingInformation } from '@signup/shared';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import * as uuid from 'uuid';
import { isSnowparkLabSignup } from './isSnowparkLabSignup';
import { shouldShowCertifications } from './shouldShowCertifications';
import * as configs from '../../utils/configurations';

export const InfoListItem = (props: { children: React.ReactNode }) => {
  return (
    <Block display="flex" justifyContent="space-between" verticalAlign="top" marginBottom={12}>
      <Block marginTop="6px" marginRight={16}>
        <img src="/static/images/rectangle-bullet.svg" />
      </Block>
      {props.children}
    </Block>
  );
};

export const InfoText = (props: { children: React.ReactNode }) => {
  return (
    <Text color={Color.White} size="large" lineHeight="30px" marginBottom={12} width="100%">
      {props.children}
    </Text>
  );
};

interface InfoListProps {
  awsType?: AwsMarketplaceType;
  developers: boolean;
  listingInformation?: ListingInformation;
  isLoading?: boolean;
  lab?: string;
}

export const InfoList = (props: InfoListProps) => {
  const { lab } = props;

  /* eslint-disable react/jsx-key */
  let marketingText = [];
  if (props.awsType === AwsMarketplaceType.ON_DEMAND) {
    marketingText = [
      <FormattedMessage id="Data-driven business decisions" />,
      <FormattedMessage id="Exponential analytics performance and cost savings" />,
      <FormattedMessage id="360-degree customer views and experiences" />,
    ];
  } else if (props.developers) {
    marketingText = [
      <FormattedMessage id="Code in Python, SQL, Java, or Scala" />,
      <FormattedMessage id="Build with structured, semi-structured, or unstructured data" />,
      <FormattedMessage id="Single platform for data engineering, application development, data science, and more" />,
    ];
  } else if (
    configs.isFeatureEnabled(FeatureFlag.FF_ENABLE_COBRANDED_SIGNUP_PAGE) &&
    (props.isLoading || props.listingInformation)
  ) {
    marketingText = [
      <Block display="flex" flexWrap="wrap">
        <FormattedMessage
          id="Receive real time updates from <providerName></providerName> automatically"
          description="Indicates who provides updates for a package. Contains a blank for the author's name."
          values={{
            providerName: function ProviderName() {
              if (props.isLoading) {
                return (
                  <Shimmer
                    role="progressbar"
                    aria-busy={true}
                    height={20}
                    width="15%"
                    marginTop={4}
                    marginLeft={4}
                    marginRight={4}
                  />
                );
              } else {
                return props.listingInformation?.providerName;
              }
            },
          }}
        />
      </Block>,
      <FormattedMessage id="Explore data, services and more without ETL" />,
      <FormattedMessage id="Scale your critical data workloads instantly, elastically, and near-infinitely across public clouds" />,
    ];
  } else if (isSnowparkLabSignup(lab)) {
    marketingText = [
      <FormattedMessage id="Create pipelines in Python with Snowpark" />,
      <FormattedMessage id="One-click tutorial setup with preconfigured environment" />,
      <FormattedMessage id="Sample code with data transformations in Python" />,
      <FormattedMessage id="Free, no additional cloud provider accounts required" />,
    ];
  } else {
    marketingText = [
      <FormattedMessage id="Gain immediate access to the Data Cloud" />,
      <FormattedMessage id="Enable your most critical data workloads" />,
      <FormattedMessage id="Scale instantly, elastically, and near-infinitely across public clouds" />,
    ];
  }

  const bulletedMarketingText = marketingText.map(text => {
    return (
      <InfoListItem key={uuid.v4()}>
        <InfoText>{text}</InfoText>
      </InfoListItem>
    );
  });

  return (
    <Block className="trial-info-list" marginBottom={24}>
      {bulletedMarketingText}
      {shouldShowCertifications(props.developers, lab) && (
        <InfoListItem>
          <Block display="flex" flexDirection="column">
            <InfoText>
              <FormattedMessage id="Snowflake is HIPAA, PCI DSS, SOC 1 and SOC 2 Type 2 compliant, and FedRAMP Authorized" />
            </InfoText>
            <Block
              className="trial-info-list-certification-icons"
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              width="85%"
              marginTop="12px"
            >
              <img src="/static/images/compliance/white/pci.png" height="40px" alt="PCI" />
              <img src="/static/images/compliance/white/hipaa.png" height="40px" alt="HIPAA" />
              <img src="/static/images/compliance/white/soc2.png" height="40px" alt="SOC 2" />
              <img src="/static/images/compliance/white/fedramp.png" height="40px" alt="FedRAMP" />
            </Block>
          </Block>
        </InfoListItem>
      )}
    </Block>
  );
};

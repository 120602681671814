import { useIntl } from 'react-intl';
import { OptionLabeledValue, Question, useOtherOption } from './model';
import { useRef } from 'react';
import { range, shuffle } from 'lodash';

const SURVEY_VERSION = 'v3';

export const useSnowflakeUsageQuestion = (): Question => {
  const { formatMessage } = useIntl();

  const options: OptionLabeledValue[] = [
    {
      value: 'Use Snowflake as a data warehouse and data lake',
      label: formatMessage({ id: 'Use Snowflake as a data warehouse and data lake' }),
    },
    {
      value: 'Run data analysis and build visualizations',
      label: formatMessage({ id: 'Run data analysis and build visualizations' }),
    },
    {
      value: 'Load data, build a data pipeline or migrate an existing warehouse',
      label: formatMessage({
        id: 'Load data, build a data pipeline or migrate an existing warehouse',
      }),
    },
    {
      value: 'Build or train a machine learning model',
      label: formatMessage({ id: 'Build or train a machine learning model' }),
    },
    {
      value: 'Build or distribute an application with Snowflake',
      label: formatMessage({ id: 'Build or distribute an application with Snowflake' }),
    },
    {
      value: 'List or buy data from the Snowflake marketplace',
      label: formatMessage({ id: 'List or buy data from the Snowflake marketplace' }),
    },
  ];
  const { current: order } = useRef(shuffle(range(options.length)));
  const shuffledOptions = order.map(index => options[index]);

  return {
    version: SURVEY_VERSION,
    questionText: {
      value: 'What will you use Snowflake for?',
      label: formatMessage({ id: 'What will you use Snowflake for?' }),
    },
    options: shuffledOptions,
  };
};

export const useSignupReasonQuestion = (): Question => {
  const { formatMessage } = useIntl();

  const options: OptionLabeledValue[] = [
    {
      value: 'Company is considering Snowflake',
      label: formatMessage({ id: 'Company is considering Snowflake' }),
    },
    {
      value: 'Training or Certification',
      label: formatMessage({ id: 'Training or Certification' }),
    },
    {
      value: 'Personal learning and development',
      label: formatMessage({ id: 'Personal learning and development' }),
    },
  ];
  const { current: order } = useRef(shuffle(range(options.length)));
  const shuffledOptions = order.map(index => options[index]);

  const otherOption = useOtherOption(false);

  return {
    version: SURVEY_VERSION,
    questionText: {
      value: 'Why are you signing up for a trial?',
      label: formatMessage({ id: 'Why are you signing up for a trial?' }),
    },
    options: [...shuffledOptions, otherOption],
  };
};

export const usePreferredLanguageQuestion = (): Question => {
  const { formatMessage } = useIntl();
  const otherOption = useOtherOption(true);

  const options = [
    {
      value: 'SQL',
      label: formatMessage({ id: 'SQL' }),
    },
    {
      value: 'Python',
      label: formatMessage({ id: 'Python' }),
    },
    {
      value: 'Java',
      label: formatMessage({ id: 'Java' }),
    },
    {
      value: 'Scala',
      label: formatMessage({ id: 'Scala' }),
    },
  ];
  const { current: order } = useRef(shuffle(range(options.length)));
  const shuffledOptions = order.map(index => options[index]);

  return {
    version: SURVEY_VERSION,
    questionText: {
      value: 'Select your preferred language(s) to work in',
      label: formatMessage({ id: 'Select your preferred language(s) to work in' }),
    },
    options: [
      ...shuffledOptions,
      {
        value: 'No coding language experience',
        label: formatMessage({ id: 'No coding language experience' }),
      },
      otherOption,
    ],
  };
};

import { AccessibleSection, Block, InfoCard } from '@snowflake/core-ui';
import React from 'react';

export function CardTemplate(props: {
  children: React.ReactNode;
  title?: string;
  subtitle?: React.ReactNode;
  aux?: React.ReactNode;
}) {
  return (
    <InfoCard title={props.title} aux={props.aux}>
      {props.subtitle ? (
        <AccessibleSection>
          <Block marginBottom={24}>{props.subtitle}</Block>
        </AccessibleSection>
      ) : null}
      {props.children}
    </InfoCard>
  );
}

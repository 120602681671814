import { Color, Text } from '@snowflake/core-ui';
import { AwsMarketplaceType, TrialType } from '@signup/shared';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

export const CardMarketingTitle = (props: {
  awsType?: AwsMarketplaceType;
  trialType?: TrialType;
  developers?: boolean;
}) => {
  let title;
  if (props.awsType === AwsMarketplaceType.ON_DEMAND) {
    title = (
      <FormattedMessage id="Please fill out the following information to complete your Snowflake subscription!" />
    );
  } else if (props.developers) {
    title = <FormattedMessage id="Build on Snowflake with $400 of credits to develop apps" />;
  } else if (
    props.trialType === TrialType.COMPETITION ||
    props.trialType === TrialType.DATA_SCIENCE_COMPETITION_JP ||
    props.trialType === TrialType.STARTUP
  ) {
    title = (
      <FormattedMessage id="Start your free Snowflake trial which includes $400 worth of free usage" />
    );
  } else if (props.trialType === TrialType.STUDENT) {
    title = (
      <FormattedMessage id="Start your 120-day free Snowflake trial which includes $400 worth of free usage" />
    );
  } else {
    title = (
      <FormattedMessage id="Start your 30-day free Snowflake trial which includes $400 worth of free usage" />
    );
  }
  return (
    <Text
      className="trial-card-marketing-text"
      fontSize={20}
      color={Color.White}
      lineHeight="30px"
      marginBottom={24}
    >
      {title}
    </Text>
  );
};

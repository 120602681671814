import { AwsMarketplaceType } from '@signup/shared';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { SignupPageProvider } from './SignupContext';
import { SignupPage } from './SignupPage';
import { PolarisSignupPage } from './PolarisSignupPage';

export interface SignupPageProps extends RouteComponentProps {
  formId: string;
  pageNotFound?: boolean;
  isAzure?: boolean;
  awsType?: AwsMarketplaceType;
  developers?: boolean;
  polaris?: boolean;
}

export const SignupPageRoot = (props: SignupPageProps) => {
  return (
    <SignupPageProvider>
      {props.polaris ? <PolarisSignupPage {...props} /> : <SignupPage {...props} />}
    </SignupPageProvider>
  );
};

/*
    Whether or not to show the Snowflake certifications text and icons on the signup page.
*/

import { isSnowparkLabSignup } from './isSnowparkLabSignup';

export const shouldShowCertifications = (developers: boolean, lab?: string): boolean => {
  if (developers || isSnowparkLabSignup(lab)) {
    return false;
  }
  return true;
};

import React, { createContext, ReactNode, useContext, useState } from 'react';
import { getLocale } from '../utils/AsyncIntlProvider';

interface StateVariable<T> {
  value: T;
  setValue: (value: T) => void;
}

interface SignupPageState {
  videoShowing: StateVariable<boolean>;
  formSubmitted: StateVariable<boolean>;
  locale: StateVariable<string>;
  docsLocale: StateVariable<string>;
}

interface props {
  children: ReactNode;
}

//locales used in the product are different from those in the docs
//the mapping is used to localize the docs links
const getDocsLocale = (locale: string) => {
  switch (locale) {
    case 'en-US':
      return 'en';
    case 'fr-FR':
      return 'fr';
    case 'ja':
      return 'ja';
    case 'ko':
      return 'ko';
    default:
      return 'en';
  }
};

const SignupPageContext = createContext<SignupPageState>({} as SignupPageState);

export const SignupPageProvider = ({ children }: props) => {
  //initialize the state
  const [videoShowing, setVideoShowing] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [locale, setLocale] = useState(getLocale());
  const [docsLocale, setDocsLocale] = useState(getDocsLocale(getLocale()));

  const initialState: SignupPageState = {
    videoShowing: {
      value: videoShowing,
      setValue: setVideoShowing,
    },
    formSubmitted: {
      value: formSubmitted,
      setValue: setFormSubmitted,
    },
    locale: {
      value: locale,
      setValue: setLocale,
    },
    docsLocale: {
      value: docsLocale,
      setValue: setDocsLocale,
    },
  };

  return <SignupPageContext.Provider value={initialState}>{children}</SignupPageContext.Provider>;
};

export const useSignupPageContext = () => {
  return useContext<SignupPageState>(SignupPageContext);
};
